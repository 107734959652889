import React, {FC, useState} from 'react';
import {dorpelBIC52} from "../../dummy";
import ContourEditor from "../ContourEditor/ContourEditor";
import ContourView from "../ContourView/ContourView";
import DorpelTopView from "../DorpelTopView/DorpelTopView";
import DorpelSideView from "../DorpelSideView/DorpelSideView";


interface JesjerProps {
}

const Jesjer: FC<JesjerProps> = () => {

    const [dorpel] = useState(dorpelBIC52);

    const [hack, setHack] = useState(1);

    return (
        <>
            <h1>Profielen</h1>
            <div className="row">
                <div className="col-3">
                    <ContourEditor contour={dorpel.topProfile} setHack={setHack} />
                </div>
                <div className="col-3">
                    <ContourEditor contour={dorpel.bottomProfile} setHack={setHack}/>
                </div>
                <div className="col-3">
                    <ContourEditor contour={dorpel.defaultNeutInner} setHack={setHack}/>
                </div>
                <div className="col-3">
                    <ContourEditor contour={dorpel.defaultNeutOuter} setHack={setHack}/>
                </div>
            </div>
            <hr/>
            <h1>Dorpel configuraties</h1>
            <div className="row bg-light">

                <div className={"col-3"}>
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th>
                                Dorpel
                            </th>
                            <th>Hoogte</th>
                            <th>Neut hoogte</th>
                            <th>
                                Bovenprofiel
                            </th>
                            <th>
                                Onderprofiel
                            </th>
                            <th>
                                Zijaanzicht
                            </th>
                            <th>
                                Bovenaanzicht
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{dorpel.code}</td>
                            <td>
                                {dorpel.height}mm
                            </td>
                            <td>
                                30mm
                            </td>
                            <td className='bg-white'>
                                <ContourView contour={dorpel.topProfile}/>
                            </td>
                            <td className='bg-white'>
                                <ContourView contour={dorpel.bottomProfile}/>
                            </td>

                            <td className='bg-white'>
                                <DorpelSideView dorpel={dorpel}/>
                            </td>
                            <td className='bg-white'>
                                <DorpelTopView dorpel={dorpel}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <h1>Configurator</h1>
            <div className="row">
                <div className="col">
                    <DorpelTopView dorpel={dorpel}/>
                </div>
            </div>


            <hr/>

            <pre style={{fontSize: "10px"}}>
                {'<?xml version="1.0" encoding="UTF-8"?>\n'}
                {'<Project Id="1" Name="OrderXYZ" ProjectNumber="OrderXYZ" DeliveryDate="2022-11-01">\n'}
                {'\t<Frame Id="' + dorpel.code + '" ParentId="" Name="Dorpel" Quantity="1">\n'}
                {'\t\t<Wire Id="1" Name="Dorpel" Radius="0" RadiusCCW="false" IsLargeArc="false">\n'}
                {'\t\t\t<StartPoint X="0" Y="0" Z="0" />\n'}
                {'\t\t\t<EndPoint X="' + (900 + 67 + 67) + '" Y="0" Z="0" />\n'}
                {'\t\t</Wire>\n'}
                {'\t\t<Wire Id="2" Name="NeutLinks" Radius="0" RadiusCCW="false" IsLargeArc="false">\n'}
                {'\t\t\t<StartPoint X="' + (67 / -2) + '" Y="0" Z="0" />\n'}
                {'\t\t\t<EndPoint X="' + (67 / -2) + '" Y="' + (dorpel.height) + '" Z="0" />\n'}
                {'\t\t</Wire>\n'}
                {'\t\t<Wire Id="3" Name="NeutRechts" Radius="0" RadiusCCW="false" IsLargeArc="false">\n'}
                {'\t\t\t<StartPoint X="' + (900 + 67 + 67 / -2) + '" Y="0" Z="0" />\n'}
                {'\t\t\t<EndPoint X="' + (900 + 67 + 67 / -2) + '" Y="' + (dorpel.height) + '" Z="0" />\n'}
                {'\t\t</Wire>\n'}
                {'\t\t<Joint Id="1" Name="JointLinks" ContraFromJoint="true" MitreCut="false" ApplyGlue="false">\n'}
                {'\t\t\t<WireEntry WireId="1" Extend="true" />\n'}
                {'\t\t\t<WireEntry WireId="2" Extend="false" />\n'}
                {'\t\t</Joint>\n'}
                {'\t\t<Joint Id="2" Name="JointLinks" ContraFromJoint="true" MitreCut="false" ApplyGlue="false">\n'}
                {'\t\t\t<WireEntry WireId="1" Extend="true" />\n'}
                {'\t\t\t<WireEntry WireId="3" Extend="false" />\n'}
                {'\t\t</Joint>\n'}
                {'\t</Frame>\n'}
                {'</Project>\n'}
            </pre>

        </>
    );
}

export default Jesjer;
