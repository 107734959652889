export interface IPoint {
    x: number;
    y: number;
}

export class Contour {

    constructor(public readonly code: string | null,
                public readonly height: number,
                public readonly points: IPoint[],
                public lines: IContourLine[]) {

    }

    public static fromPoints(code: string, points: IPoint[]): Contour {

        let height = 120;
        if (points.length > 0) {
            height = points.reduce(((current, prev) => prev?.y > current.y ? prev : current)).y;
        }

        return new Contour(code, height, points, Contour.calcLines(points));
    }

    private static calcLines(points: IPoint[]): IContourLine[] {

        const lines: IContourLine[] = [];

        points.forEach((p, index) => {
            if (index > 0) {
                lines.push({
                    from: points[index - 1],
                    to: points[index],
                    radius: 0,
                    curved: false,
                    curveCcw: false
                })
            }
        });

        return lines;
    }

    public pushPoint(p: IPoint): void {
        if (this.points.length > 0 && this.points[this.points.length - 1] == p) return;
        this.points.push(p);
        this.lines = Contour.calcLines(this.points);
    }

    public popPoint(): void {
        this.points.pop();
        this.lines.pop();
    }


    public getMaxY(): number {
        const yy = this.points.map(l => l.y);
        return Math.max(...yy);
    }

    public getMaxX(): number {
        const xx = this.points.slice(1, this.points.length - 1).map(l => l.x);
        return Math.max(...xx);
    }
}

export class Dorpel {

    public readonly dagMaat: number = 600;
    public readonly aantalNeuten: number = 3;

    public constructor(public readonly code: string,
                       public readonly height: number,
                       public readonly topProfile: Contour,
                       public readonly bottomProfile: Contour,
                       public readonly defaultNeutInner: Contour,
                       public readonly defaultNeutOuter: Contour) {

    }

    public getWidth(): number {
        return this.topProfile.getMaxY();
    }

    public getLength(): number {
        return this.aantalNeuten * 67 + (this.aantalNeuten - 1) * this.dagMaat;
    }
}


export interface IContourLine {
    from: IPoint;
    to: IPoint;
    curved: boolean;
    curveCcw: boolean | null;
    radius: number | null;
}

