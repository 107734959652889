import React, {FC, useState} from 'react';
import {Dorpel, IPoint} from "../../core/models";
import {dorpelBIC52} from "../../dummy";


interface DorpelSideViewProps {
    dorpel: Dorpel;
}

const DorpelSideView: FC<DorpelSideViewProps> = (props) => {

    const [width] = useState(props.dorpel.height);
    const [height] = useState(props.dorpel.getWidth());

    const viewBox = [
        0,
        0,
        width,
        height,
    ];

    const generateSideView = (): IPoint[] => {
        const points: IPoint[] = [];

        const topPoints = props.dorpel.topProfile.points;
        points.push(...topPoints);

        let bottomPoints =  props.dorpel.bottomProfile.points
            .map(p => ({
                x: p.x * -1,
                y: p.y
            }))
            .reverse();

        const frontBottom = bottomPoints[0];

        const bottomOffset =  props.dorpel.height - frontBottom.x;

        bottomPoints = bottomPoints.map(p => ({x: p.x + bottomOffset, y: p.y}));

        points.push(...bottomPoints);
        points.push(topPoints[0]);
        return points;
    }

    return (
        <div>
            <svg viewBox={viewBox.join(' ')}
                 width={width} height={height}
                 className={"view-2d dorpel-side-view"}
                 shapeRendering={"geometricPrecision"}>

                <line className={"axis axis-x"} width={1} x1={viewBox[0]} y1={0} x2={viewBox[0] + width}
                      y2={0}></line>
                <line className={"axis axis-y"} width={1} x1={0} y1={viewBox[1]} x2={0}
                      y2={viewBox[1] + height}></line>

                <polyline points={generateSideView().map(p => `${p.x} ${dorpelBIC52.getWidth() - p.y}`).join(', ')} fill="none"
                          stroke="black"></polyline>

            </svg>
        </div>);
};

export default DorpelSideView;
