import React, {FC, useState} from 'react';
import {Contour, Dorpel, IPoint} from "../../core/models";
import "./DorpelTopView.scss";


interface DorpelTopViewProps {
    dorpel: Dorpel;
}

const DorpelTopView: FC<DorpelTopViewProps> = (props) => {

    const [width] = useState(props.dorpel.getLength());
    const [height] = useState(props.dorpel.getWidth());

    const [svgWidth] = useState(1000);
    const [svgHeight] = useState();

    const viewBox = [
        0,
        0,
        width + 1,
        height + 1,
    ];


    const generateTopView = (profile: Contour): number[] => {
        let hLines: number[] = [];

        let prev: number | null = null;
        profile.points.forEach(l => {

            if (l.y === prev) {
                return;
            }
            hLines.push(l.y);
            prev = l.y;
        });

        return hLines;
    };


    const renderNeut = (index: number = 0, total: number = 2) => {

        let points: IPoint[] = [];

        const width = 67;

        let outer = props.dorpel.defaultNeutOuter;
        let inner = props.dorpel.defaultNeutInner;

        const outerPoints = outer.points;
        points.push(...outerPoints);

        let innerPoints = inner.points
            .map(p => ({
                x: p.x * -1,
                y: p.y
            }))
            .reverse();

        const outsideFront = outerPoints[0];

        const bottomOffset = width - outsideFront.x;
        innerPoints = innerPoints.map(p => ({x: p.x + bottomOffset, y: p.y}));

        points.push(...innerPoints);
        points.push(outerPoints[0]);

        if (index === total - 1) {
            points = points.map(p => ({x: props.dorpel.getLength() - p.x, y: props.dorpel.defaultNeutOuter.getMaxY() - p.y}))
        } else {
            points = points.map(p => ({x: p.x, y: props.dorpel.defaultNeutOuter.getMaxY() - p.y}))
        }

        return points;
    };

    return (
        <div>
            <svg viewBox={viewBox.join(' ')}
                 width={svgWidth} height={svgHeight}
                 className={"view-2d dorpel-top-view"}
                 shapeRendering={""}>

                {generateTopView(props.dorpel.topProfile).map((y, index) => {
                    return (
                        <line key={index} x1={0} y1={height - y} x2={width} y2={height - y} fill={"none"}
                              stroke={"#ddd"}/>
                    );
                })}

                <rect x1={0} y1={0} width={width} height={props.dorpel.bottomProfile.getMaxY()} fill={"none"}
                      stroke={"#bbb"}/>

                <polyline points={renderNeut().map(p => `${p.x} ${p.y}`).join(', ')} className="neut"/>
                <polyline points={renderNeut(1).map(p => `${p.x} ${p.y}`).join(', ')} className="neut"/>
            </svg>
        </div>);
};

export default DorpelTopView;
