import {Contour, Dorpel} from "./core/models";

export const dorpelTopProfile = Contour.fromPoints('BI_C52 Top', [
    {x: 20, y: 0},
    {x: 20, y: 20},
    {x: 0, y: 60},
    {x: 0, y: 70},
    {x: 20, y: 70},
    {x: 20, y: 100},
    {x: 15, y: 105},
    {x: 15, y: 120},
]);

export const dorpelBottomProfile = Contour.fromPoints('BI_C52 Bottom', [
    {x: 0, y: 0},
    {x: 0, y: 5},
    {x: 5, y: 5},
    {x: 5, y: 15},
    {x: 0, y: 15},
    {x: 0, y: 100},
    {x: 5, y: 100},
    {x: 5, y: 115},
    {x: 0, y: 115},
    {x: 0, y: 120},
]);


export const neutInsideProfile = Contour.fromPoints("BI_C52 - Neut Binnen", [
    {x: 40, y: 0},
    {x: 3, y: 0},
    {x: 0, y: 3},
    {x: 0, y: 57},
    {x: 3, y: 60},
    {x: 25, y: 60},
    {x: 25, y: 60},
    {x: 25, y: 117},
    {x: 28, y: 120},
    {x: 40, y: 120},
]);


export const neutOutsideProfile = Contour.fromPoints("BI_C52 Neut Buiten", [
    {x: 0, y: 0},
    {x: 0, y: 120},
]);


export const dorpelBIC52 = new Dorpel("BI_C52", 60, dorpelTopProfile, dorpelBottomProfile, neutInsideProfile, neutOutsideProfile);


export const initialState = {
    code: dorpelBIC52.code,
    height: dorpelBIC52.height,
    width: dorpelBIC52.getWidth(),
    topProfile: dorpelBIC52.topProfile,
    bottomProfile: dorpelBIC52.bottomProfile,
    defaultNeutInner: dorpelBIC52.defaultNeutInner,
    defaultNeutOuter: dorpelBIC52.defaultNeutOuter,
};