/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ORDERS</span>
        </div>
      </div>

      <SidebarMenuItem to='/apps/orders/overview' title='Overzicht' hasBullet={true} />
      <SidebarMenuItem to='/apps/orders/neworder' title='Nieuwe Order' hasBullet={true} />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>PLANNING</span>
        </div>
      </div>

      <SidebarMenuItem to='/apps/planning/day' title='Dag' hasBullet={true} />
      <SidebarMenuItem to='/apps/planning/week' title='Week' hasBullet={true} />
      <SidebarMenuItem to='/apps/planning/month' title='Maand' hasBullet={true} />

      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>BEHEER</span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

<SidebarMenuItemWithSub
        to='/apps/configuration/'
        icon='/media/icons/duotune/coding/cod003.svg'
        title='Configuratie'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/apps/configuration/poc' title='PoC' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/frames/' title='Frames' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/profiles/' title='Profielen' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/neuten/' title='Neuten' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/price/' title='Prijs' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/imports/' title='Imports' hasBullet={true} />
        <SidebarMenuItem to='/apps/configuration/exports/' title='Exports' hasBullet={true} />
      </SidebarMenuItemWithSub> 

      <SidebarMenuItem
        to='/apps/customer-management/customers'
        icon='/media/icons/duotune/general/gen052.svg'
        title='Klanten'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/apps/product-management/products'
        icon='/media/icons/duotune/abstract/abs029.svg'
        title='Producten'
        fontIcon='bi-layers'
      />

    <SidebarMenuItem
        to='/apps/employee-management/employees'
        icon='/media/icons/duotune/general/gen056.svg'
        title='Medewerkers'
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Gebruikers'
        fontIcon='bi-layers'
      />

    </>
  )
}

export {SidebarMenuMain}
