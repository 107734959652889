//import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import AppPocSil from '../../../_pocsil/App'

// const wizardsBreadCrumbs: Array<PageLink> = [
//   {
//     title: 'Wizards',
//     path: '/crafted/pages/wizards/horizontal',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const PocSilPage : React.FC = () => (
        <>
           <AppPocSil />
        </>

  // <Routes>
  //   <Route element={<Outlet />}>
  //     <Route
  //       path='/apps/orders/overview'
  //       element={
  //         <>
  //           <PageTitle 
  //           // breadcrumbs={wizardsBreadCrumbs}
  //           >Blank</PageTitle>          
  //           <div>Blank</div>
  //         </>
  //       }
  //     />  
  //     <Route index element={<Navigate to='/apps/orders/overview' />} />
  //   </Route>
  // </Routes>
)

export default PocSilPage
