import React, {FC, ReactElement, useState} from 'react';
import './ContourView.scss'
import {Contour} from "../../core/models";

interface ContourViewProps {
    contour: Contour;
    padding?: number;
}

const ContourView: FC<ContourViewProps> = (props) => {

    const padding = 0;
    const [height] = useState(props.contour.getMaxY())
    const [width] = useState(props.contour.getMaxX())
    const [viewBox] = useState([
        0,
        0,
        width + padding * 2,
        height + padding * 2,
    ])

    const y = (y: number): number => {
        return height  - y;
    }
    const x = (x: number): number => {
        return x;
    }

    const getLineElements = (): ReactElement[] => {

        const elements: ReactElement[] = [];
        props.contour.lines.forEach((l, index) => {
            elements.push((<line key={index} className={"contour-line"} x1={x(l.from.x)} y1={y(l.from.y)} x2={x(l.to.x)}
                                 y2={y(l.to.y)}></line>));
        });

        return elements;
    }

    return (
        <svg viewBox={viewBox.join(' ')}
             width={viewBox[2]} height={viewBox[3]}
             className={"view-2d contour-view"}
             shapeRendering={""}>

            <line className={"axis axis-x"} width={1} x1={viewBox[0]} y1={0} x2={viewBox[0] + viewBox[2]} y2={0}></line>
            <line className={"axis axis-y"} width={1} x1={0} y1={viewBox[1]} x2={0} y2={viewBox[1] + viewBox[3]}></line>

            {getLineElements()}
        </svg>
    );
}


export default ContourView;