/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
         Klant gegevens
          
        </h2>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            TODO
          </div>

         
        </div>
      </div>
    </div>
  )
}

export {Step1}
