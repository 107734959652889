import React, {FC, FormEvent, SetStateAction, useState} from 'react';
import './ContourEditor.scss';
import {Contour, IPoint} from "../../core/models";

interface ContourEditorProps {
    contour: Contour,
    setHack: React.Dispatch<SetStateAction<number>>;
}

const parsePoint = (s: string): IPoint | null => {

    const xy = s.split(',').map(x => x.trim()).filter(x => x.length > 0);

    if (xy.length !== 2 || Number.isNaN(xy[0]) || Number.isNaN(xy[1]) || Number.isNaN(Number.parseInt(xy[0])) || Number.isNaN(Number.parseInt(xy[1]))) {
        return null;
    }
    return {x: Number(xy[0]), y: Number(xy[1]),};

};

const ContourEditor: FC<ContourEditorProps> = (props) => {

    const contour = props.contour;

    const setHack = props.setHack;

    const [contourState, updateContourState] = useState({
        points: contour.points,
        lines: contour.lines,
    });

    const [height] = useState(contour.height);
    const [point, setPoint] = useState('');

    const paddingTop = 4;
    const paddingBottom = 2;
    const paddingStart = 20;

    const w = 80;
    const bounds = {
        x1: paddingStart,
        y1: paddingTop,
        width: w,
        height: height,
        x2: paddingStart + w,
        y2: paddingTop + height
    }

    const viewBoxBounds = {
        x: 0,
        y: 0,
        width: paddingStart + bounds.width,
        height: paddingTop + bounds.height + paddingBottom,
    }

    const [viewBox] = useState([viewBoxBounds.x, viewBoxBounds.y, viewBoxBounds.width, viewBoxBounds.height].join(' '));

    const tickSize = 10;
    const ticks: number[] = [];
    for (let y = bounds.height; y > 0; y -= tickSize) {
        ticks.push(y);
    }


    const onAddPointSubmit = (event: FormEvent) => {
        event.preventDefault();
        const p = parsePoint(point);
        if (p) {                              
            contour.pushPoint(p);
            updateContourState({
                points: contour.points,
                lines: contour.lines,
            });
            setPoint('');
            setHack(value => value + 1);
        }
    }

    const onRemovePointSubmit = (event: React.MouseEvent) => {
        event.preventDefault();

        if (contour.points.length === 1) {
            return;
        }

        contour.popPoint();
        updateContourState({
            points: contour.points,
            lines: contour.lines,
        });
        setHack(value => value - 1);
    }

    return (
        <>
            <h3>{contour.code}</h3>
            <div className={"d-flex"}>
                <div style={{width: "260px"}}>
                    <svg
                        className="contour-editor"
                        viewBox={viewBox} width="100%"
                        preserveAspectRatio="xMidYMid meet"
                        shapeRendering="geometricPrecision">
                        <g>
                            <rect x={bounds.x1} y={bounds.y1} width={bounds.width} height={bounds.height}
                                  fill="#fafafa"/>

                            <line x1={bounds.x1 + bounds.width / 2} y1={bounds.y1} x2={bounds.x1 + bounds.width / 2}
                                  y2={bounds.y2} className={"base-line"}/>

                            <line x1={bounds.x1} y1={bounds.y1} x2={bounds.x1} y2={bounds.y2}
                                  className={"ruler-base"}
                                  vectorEffect={"non-scaling-stroke"}/>

                            {ticks.map(tick => {
                                const y = bounds.y2 - tick;
                                return (
                                    <line key={`ruler-tick-${tick}`} x1={bounds.x1} y1={bounds.y2 - tick}
                                          x2={bounds.x1 + 2}
                                          y2={y}
                                          className={"ruler-tick"}
                                          vectorEffect={"non-scaling-stroke"}/>

                                );
                            })}

                            {ticks.map(tick => {
                                const y = bounds.y2 - tick;
                                return (
                                    <text key={`ruler-tick-label-${tick}`} x={bounds.x1} y={y} fontSize={4}
                                          className={"ruler-tick-label"}>{tick}&nbsp;</text>

                                );
                            })}

                            <rect x={bounds.x1} y={bounds.y1 - 2} width={bounds.width} height={2} fill="#888"/>
                            <rect x={bounds.x1} y={bounds.y2} width={bounds.width} height={2} fill="#888"/>


                            {contourState.points.map((p, index) => {
                                const x = bounds.x1 + bounds.width / 2 + p.x;
                                const y = bounds.y2 - p.y;

                                return (
                                    <circle key={`point-${index}`} cx={x} cy={y}
                                            r={.6}
                                            className={"point"}/>);
                            })}


                            {contourState.lines.map((l, index) => {
                                const x1 = bounds.x1 + bounds.width / 2 + l.from.x;
                                const y1 = bounds.y2 - l.from.y;
                                const x2 = bounds.x1 + bounds.width / 2 + l.to.x;
                                const y2 = bounds.y2 - l.to.y;

                                return (
                                    <line key={`line-${index}`} x1={x1} y1={y1} x2={x2} y2={y2} className={"line"}/>);
                            })}

                        </g>
                    </svg>
                </div>

                <div className="ms-2">
                    <form onSubmit={(e) => onAddPointSubmit(e)}>
                        <div className={"input-group"}>
                            <input type="text" className="form-control" placeholder="Punt: 10,10"
                                   pattern={"[0-9]+,[0-9]+"}
                                   value={point}
                                   onChange={(e) => setPoint(e.target.value)}
                            />
                            <button type="submit" className={"btn btn-primary"}>+</button>
                        </div>
                        <table className="table table-sm">
                            <thead>
                            <tr>
                                <th>Point</th>
                                <th className={"text-end"}>X</th>
                                <th className={"text-end"}>Y</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {contourState.points.map((l, index) => {
                                return (
                                    <tr key={`l-${index}`}>
                                        <td>
                                            #{index + 1}
                                        </td>
                                        <td className={"text-end"}>
                                            {l.x}
                                        </td>
                                        <td className={"text-end"}>
                                            {l.y}
                                        </td>
                                        <td className={"text-center"}>
                                            {contourState.points.length - 1 == index ? (
                                                <a href="" onClick={(e) => onRemovePointSubmit(e)}>delete</a>) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContourEditor;


